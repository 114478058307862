<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah <b>Daftar Karyawan</b> yang ada di {{clinic_name}}
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push({ name: 'employee-add' })"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div>
              <HrTable purpose="master"/>
            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import HrTable from '@/component/human-resource/Table.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: 'List',

  components: {
    Card,
    HrTable
  },
  data(){
    return{
      manipulateBtn:false,
      clinic_name : window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    }
  },

  methods:{
    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      
      
      if(access_right[a] == "7002"){
        this.manipulateBtn = true
      }
      
    }
    },
  },
  
  mounted() {
    this.setActiveMenu()
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Karyawan" },
      { title: "Daftar Karyawan" },
    ])
    
  },

}
</script>

<style>
</style>